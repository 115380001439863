interface Locale {
  [key: string]: {
    en_US: string;
    ar_SA: string;
    tr_TR: string;
  };
}

const locales: Locale = {
  语言: {
    en_US: 'English',
    ar_SA: 'لغة',
    tr_TR: 'dil',
  },
  '请求地址异常，请检查': {
    en_US: 'The request address is abnormal, please check',
    ar_SA: 'عنوان الطلب غير طبيعي، يرجى التحقق',
    tr_TR: 'İstek adresi anormal, lütfen kontrol edin',
  },
  '请求网络异常，请稍后': {
    en_US: 'Request network exception, please wait.',
    ar_SA: 'طلب استثناء الشبكة، يرجى الانتظار.',
    tr_TR: 'Ağ istisnası isteyin, lütfen bekleyin.',
  },
  请求失败: {
    en_US: 'Request failed',
    ar_SA: 'فشل الطلب',
    tr_TR: 'İstek başarısız oldu',
  },
  公会: {
    en_US: 'Angency',
    ar_SA: 'يتبع CY',
    tr_TR: 'Acente',
  },
  搜索: {
    en_US: 'Search',
    ar_SA: 'يبحث',
    tr_TR: 'aramak',
  },
  '搜索公会名称/公会长昵称/ID': {
    en_US: 'Search for agency name/Agent nickname/ID',
    ar_SA: 'ابحث عن اسم الوكالة/اسم الوكيل/ ID للوكالة',
    tr_TR: 'Ajans adını/takma adını/kimliğini arayın',
  },
  '搜索姓名/ID': {
    en_US: 'Search for name / ID',
    ar_SA: 'يبحث عن الاسم / الهوية',
    tr_TR: 'Isim / Kimlik aramak',
  },
  加入: {
    en_US: 'Join',
    ar_SA: 'إنضمام',
    tr_TR: 'Katılmak',
  },
  提示: {
    en_US: 'Tips',
    ar_SA: 'إشعار',
    tr_TR: 'İPUÇLARI',
  },
  '确定加入该公会嘛？': {
    en_US: 'Confirm to apply to join this agency?',
    ar_SA: 'هل أنت متؤكد من الانضمام إلى الوكالة هذه؟',
    tr_TR: 'Bu ajansa katılmak için başvuruyu onaylıyor musunuz?',
  },
  成员申请: {
    en_US: 'Member Application',
    ar_SA: 'طلبات الأعضاء',
    tr_TR: 'Üye Başvurusu',
  },
  待处理: {
    en_US: 'Pending',
    ar_SA: 'قيد المعالجة',
    tr_TR: 'İslemde',
  },
  已同意: {
    en_US: 'Agreed',
    ar_SA: 'موافق',
    tr_TR: 'İlgi',
  },
  已拒绝: {
    en_US: 'Rejected',
    ar_SA: 'مرفوض',
    tr_TR: 'Reddedildi',
  },
  拒绝: {
    en_US: 'Refuse',
    ar_SA: 'يرفض',
    tr_TR: 'Reddetmek',
  },
  同意: {
    en_US: 'Agree',
    ar_SA: 'موافقة',
    tr_TR: 'İlgili',
  },
  会员: {
    en_US: 'Member',
    ar_SA: 'عضو',
    tr_TR: 'Üye',
  },
  邀请: {
    en_US: 'Invite',
    ar_SA: 'مشاركة',
    tr_TR: 'Ilaç',
  },
  移除公会管理员: {
    en_US: 'Remove agency admin',
    ar_SA: 'إزالة مشرف الوكالة',
    tr_TR: 'Ajans yöneticisini kaldır',
  },
  设置公会管理员: {
    en_US: 'Set up agency admin',
    ar_SA: 'تعيين مشرف الوكالة',
    tr_TR: 'Ajans yöneticisini ayarla',
  },
  移出公会: {
    en_US: 'Remove from the agency',
    ar_SA: 'إزالته من الوكالة',
    tr_TR: 'Ajanstan kaldır',
  },
  没有更多了: {
    en_US: 'No more',
    ar_SA: 'لا يوجد المزيد',
    tr_TR: 'Daha fazla yok',
  },
  '申请加入失败，请稍后重试~': {
    en_US: 'Application to join failed, please try again later',
    ar_SA: 'فشل تقديم الطلب للانضمام، يرجى المحاولة مرة أخرى في وقت لاحق',
    tr_TR: 'Katılmak için başvuru yapılamadı, lütfen daha sonra tekrar deneyin',
  },
  '申请加入成功，请耐心等待公会通过~': {
    en_US: 'The application to join is successful. Please wait patiently for the guild to approve it.',
    ar_SA: 'تم قبول طلب الانضمام بنجاح، يرجى الانتظار بصبر حتى تتم الموافقة على النقابة.',
    tr_TR: 'Katılım başvurunuz başarılı oldu, lütfen loncanın onaylanmasını sabırla bekleyin.',
  },
  '同意申请失败，请稍后重试~': {
    en_US: 'Agree to apply failed, please try again later',
    ar_SA: 'فشل الموافقة على الطلب للانضمام، يرجى المحاولة مرة أخرى في وقت لاحق',
    tr_TR: 'İlgiye cevaplanmadı, lütfen daha sonra tekrar deneyin',
  },
  '同意成功': {
    en_US: 'Agree successfully',
    ar_SA: 'تم الموافقة بنجاح',
    tr_TR: 'İlgili',
  },
  '拒绝成功': {
    en_US: 'Reject successfully',
    ar_SA: 'تم الرفض بنجاح',
    tr_TR: 'Reddedildi',
  },
  '确认同意{{name}}': {
    en_US: 'Confirm to agree {{name}}',
    ar_SA: 'هل ترغب في الموافقة على {{name}}',
    tr_TR: 'İlgiyi onaylamak istiyor musunuz',
  },
  '确认同意{{name}} (ID:{{id}})加入公会吗？': {
    en_US: 'Confirm to agree {{name}} (ID:{{id}}) to join the agency?',
    ar_SA: 'هل ترغب في الموافقة على {{name}} (ID:{{id}}) للانضمام للنقابة؟',
    tr_TR: '{{name}} (ID:{{id}}) katılımını onaylamak istiyor musunuz?',
  },
  '确认拒绝{{name}} (ID:{{id}})加入公会吗？': {
    en_US: 'Confirm to reject {{name}} (ID:{{id}}) to join the agency?',
    ar_SA: 'هل ترغب في رفض {{name}} (ID:{{id}}) للانضمام للنقابة؟',
    tr_TR: '{{name}} (ID:{{id}}) katılımını reddetmek istiyor musunuz?',
  },
  '拒绝申请失败，请稍后重试~': {
    en_US: 'Reject application failed, please try again later',
    ar_SA: 'فشل رفض الطلب للانضمام، يرجى المحاولة مرة أخرى في وقت لاحق',
    tr_TR: 'Katılmak için başvuru reddedildi, lütfen daha sonra tekrar deneyin',
  },
  '操作ID:{{id}}': {
    en_US: 'Executor ID: {{id}}',
    ar_SA: 'ID العملية: {{id}}',
    tr_TR: 'Operasyon ID: {{id}}',
  },
  '公会长': {
    en_US: 'Owner',
    ar_SA: 'وكيل',
    tr_TR: 'Ajans Sahibi',
  },
  公会管理员: {
    en_US: ' Admin',
    ar_SA: 'ادمن ',
    tr_TR: 'Yönetici',
  },
  公会成员: {
    en_US: 'Member',
    ar_SA: 'أعضاء ',
    tr_TR: 'Üyeler',
  },
  '确认将{{name}} (ID:{{id}})从公会中移除吗？': {
    en_US: 'Confirm to remove {{name}} (ID:{{id}}) from the agency?',
    ar_SA: 'هل ترغب في حذف {{name}} (ID:{{id}}) من النقابة؟',
    tr_TR: '{{name}} (ID:{{id}}) nın katılımını kaldırmak istiyor musunuz?',
  },
  移除成功: {
    en_US: 'Remove successfully',
    ar_SA: 'تم الحذف بنجاح',
    tr_TR: 'Kaldırıldı',
  },
  '移除失败，请稍后重试~': {
    en_US: 'Remove failed, please try again later',
    ar_SA: 'فشل الحذف، يرجى المحاولة مرة أخرى في وقت لاحق',
    tr_TR: 'Kaldırılamadı, lütfen daha sonra tekrar deneyin',
  },
  '确认设置{{name}} (ID:{{id}})为管理员吗？': {
    en_US: 'Confirm to set {{name}} (ID:{{id}}) as admin?',
    ar_SA: 'هل تريد تأكيد تعيين {{name}} (ID:{{id}}) كمسؤول؟',
    tr_TR: '{{name}}(ID:{{id}}) adlı kişinin ajans yöneticisi olarak ayarlanması onaylansın mı?',
  },
  '确认从管理员中移除 {{name}}(ID:{{id}}) 吗？': {
    en_US: 'Confirm to remove {{name}} (ID:{{id}}) from the admin?',
    ar_SA: 'متؤكد في إزالة {{0}}(ID:{{id}}) من مشرفين الوكالة؟',
    tr_TR: "{{0}}(ID:{{1}})'nin yöneticiden kaldırılması onaylansın mı?",
  },
  设置公会管理员成功: {
    en_US: 'Set up agency admin successfully',
    ar_SA: 'تم تعيين المشرف',
    tr_TR: 'Ajans yöneticisini başarıyla ayarladınız',
  },
  移除公会管理员成功: {
    en_US: 'Removed the admin successfully',
    ar_SA: 'تمت إزلة مشرف الوكالة',
    tr_TR: 'Yönetici başarıyla kaldırıld',
  },
  '设置公会管理员失败，请稍后重试': {
    en_US: 'Failed to set up the agency admin, please try again later.',
    ar_SA: 'فشل التعيين،يرجى تجربة مرة اخرى',
    tr_TR: 'Ajans yöneticisi ayarlanamadı.Lütfen daha sonra tekrar deneyin.',
  },
  '移除管理员失败，请稍后重试': {
    en_US: 'Failed to remove agency admin please try again later',
    ar_SA: 'فشلت الإزالة، يرجى تجربة مرة اخرى',
    tr_TR: 'Ajans yöneticisi kaldırılamadı, lütfen daha sonra tekrar deneyin',
  },
  成员数量: {
    en_US: 'Members',
    ar_SA: 'عدد الاعضاء',
    tr_TR: 'Kullanıcılar',
  },
  邀请成员: {
    en_US: 'Invite Member',
    ar_SA: 'دعوة عضو',
    tr_TR: 'Kullanıcı Ekle',
  },
  数据中心: {
    en_US: 'Data Center',
    ar_SA: 'مركز البيانات',
    tr_TR: 'Veri Merkezi',
  },
  会员收到硬币: {
    en_US: 'Member Received Coins',
    ar_SA: 'تم استلام القيمة',
    tr_TR: 'Yayıncı Coins Sıralaması',
  },
  复制成功: {
    en_US: 'Copy successfully',
    ar_SA: 'تم النسخ بنجاح',
    tr_TR: 'Başarıyla kopyalandı',
  },
  公会不存在: {
    en_US: 'Angency not exist',
    ar_SA: 'الجمعية غير موجودة',
    tr_TR: 'Takım mevcut degil',
  },
  已邀请: {
    en_US: 'Invited',
    ar_SA: 'تم الدعوة',
    tr_TR: 'Davet edildi',
  },
  邀请成功: {
    en_US: 'Invite successfully',
    ar_SA: 'تم الدعوة بنجاح',
    tr_TR: 'Davet edildi',
  },
  '邀请失败，请稍后重试': {
    en_US: 'Invite failed, please try again later',
    ar_SA: 'فشل الدعوة، يرجى المحاولة مرة أخرى في وقت لاحق',
    tr_TR: 'Davet edilemedi, lütfen daha sonra tekrar deneyin',
  }
};

export default locales;
