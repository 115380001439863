import { t } from '@/utils/i18n'

const langClass = {
  zh: 'zhlang',
  en: 'enlang',
  ar: 'arlang',
  tr: 'trlang',
};

const langFontClass = {
  en: 'enfont',
  ar: 'arfont',
  tr: 'trfont',
};


const sourceList = {
  hybrid: '0', // 本项目
  hoby: '1', // 旧项目
  native: '2', // 原生app 跳转进入
}

export default { REQUEST_EXPIRED: t('请求已过期'), langClass, langFontClass, sourceList };
